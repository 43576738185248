.shape {
  fill: $bright-yellow--lighter;
  position: absolute;
  z-index: -1;
}

.shape__circle {
  aspect-ratio: 1 / 1;
}

@keyframes floatTransform {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-12%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes floatMargins {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: -30px;
  }

  100% {
    margin-top: 0;
  }
}

.home-cards {
  overflow: hidden;

  .container {
    position: relative;
  }

  .shape__circle {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 24px;
    left: -34px;
  }

  .shape__rect {
    width: 30px;
    height: 200px;
    transform: rotate(-30deg);
    right: 90px;
    bottom: 41%;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .home-cards {
    .shape__circle {
      width: 260px;
      height: 260px;
      top: 8px;
      left: calc(#{$container__gutter--wide} + 8.62%);
    }

    .shape__rect {
      width: 63px;
      height: 470px;
      right: 0;
      bottom: 15%;
    }
  }
}

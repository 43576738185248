.call-to-action-block {
  border-radius: .4rem;
  background-color: $deep-purple;
  padding: 4.4rem 2.4rem;
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  color: $warm-grey--light;

  a:not(.button) {
    color: inherit;
    @extend .link--thick-underline-on-hover;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .call-to-action-block {
    padding: 7.2rem 9.6rem 8rem;
  }
}

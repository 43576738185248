.super-call-to-action {
  text-align: center;
  background-color: $bright-yellow--lighter;
  @include vertical-padding(16, top);
  @include vertical-padding(16, bottom);

  h2 {
    @include vertical-margin(4, bottom);
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  background-color: $warm-grey--light;
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  border-radius: .4rem;

  > h3 {
    margin: 0;
  }
}

// Follow on stack
.matrix--accordion + .matrix--accordion {
  margin-top: map-get($vertical-spacers--mobile, 12) * -1;
}

.accordion__trigger {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  font-weight: inherit;
  padding: 1.8rem 1.6rem 2rem 2.4rem;
  background-color: transparent;

  &:hover {
    cursor: pointer;

    .accordion__title {
      color: $purple-rain--darker__select;
    }

    .accordion__icon {
      background-color: $purple-rain--darker__select;
    }
  }

  &:focus-visible {
    border: 3px solid $purple-rain--darker__select;
    box-shadow: 0px 0px 0px 3px $accessibility__highlight;
  }
}

.accordion__title {
  flex-grow: 1;
  display: block;
  color: $purple-rain--darker;
  font-family: $font-family--apercu;
  font-weight: 700;
  font-size: $h4__font-size--mobile;
  line-height: $h4__line-height--mobile;
  margin-top: .3rem;
  transition: $transition;
}

.accordion__icon {
  flex-shrink: 0;
  flex-grow: 0;
  display: block;
  margin-left: 2.4rem;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $purple-rain--darker;
  border-radius: 100%;
  color: $warm-grey--light;
  transition: background-color $transition__duration ease, transform .2s ease-out;

  svg {
    display: block;
    width: 14px;
    height: auto;
    margin-top: 1px;
  }
}

.accordion--expanded {
  .accordion__icon {
    transform: rotate(-180deg);
  }

  .accordion__body {
    display: block;
  }
}

.accordion__body {
  display: none;
  padding: 0 3.2rem 2.8rem 2.4rem;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--accordion + .matrix--accordion {
    margin-top: map-get($vertical-spacers--desktop, 12) * -1;
  }

  .accordion__trigger {
    padding: 2.4rem 2.4rem 2.8rem 3.2rem;
  }

  .accordion__icon {
    width: 40px;
    height: 40px;

    svg {
      width: 18px;
      height: auto;
      margin-top: 2px;
    }
  }

  .accordion__title {
    font-size: $h4__font-size--desktop;
    line-height: $h4__line-height--desktop;
    margin-top: .5rem;
  }

  .accordion__body {
    padding: .1rem 8.8rem 4rem 3.2rem;
  }
}

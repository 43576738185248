//--------//
// Apercu //
//--------//

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-black-italic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-bold-italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-extralight-italic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-extralight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-light-italic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-medium-italic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-thin-italic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("/dist/fonts/apercu/apercu-thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

//-------//
// Fugue //
//-------//

@font-face {
  font-family: "Fugue";
  src: url("/dist/fonts/fugue/fugue_regular.otf") format("opentype");
  font-display: swap;
}

//---------//
// Life LT //
//---------//

@font-face {
  font-family: "Life LT";
  src: url("/dist/fonts/life-lt/Linotype - Life LT Italic.ttf") format("truetype");
  font-display: swap;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $font-family--apercu;
  font-weight: 300;
  color: $dark-grey;
  font-size: $base__font-size--mobile;
  line-height: $base__line-height--mobile;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
}

body.form-submission {
  font-size: 1.6rem;
  line-height: 1.4;

  h2 {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 1.8rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1,
h2,
h3 {
  font-family: $font-family--fugue;
  font-weight: 400;
}

h1 {
  font-size: $h1__font-size--mobile;
  line-height: $h1__line-height--mobile;
  @include vertical-margin(18, top);
  @include vertical-margin(8, bottom);

  &.h1--subhead-after {
    @include vertical-margin(7, bottom);
  }
}

.h1--small,
h2 {
  font-size: $h2__font-size--mobile;
  line-height: $h2__line-height--mobile;
}

h2 {
  @include vertical-margin(11, top);
  @include vertical-margin(9, bottom);
}

h3 {
  font-size: $h3__font-size--mobile;
  line-height: $h3__line-height--mobile;
  @include vertical-margin(9, top);
  @include vertical-margin(8, bottom);
}

h4 {
  font-size: $h4__font-size--mobile;
  line-height: $h4__line-height--mobile;
  @include vertical-margin(2, bottom);
  font-weight: 500;
}

.arrow-heading {
  font-size: 2.4rem;
  line-height: 3.2rem;

  a {
    display: inline-block;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    display: inline;
    margin-left: .6rem;
    transform: translateY(.1rem);
    transition: $transition;
  }

  &:hover svg {
    transform: translateY(.1rem) translateX(.4rem);
  }
}

p {
  @include vertical-margin(8, bottom);
}

b, strong {
  font-weight: 700;
}

.standfirst {
  font-family: $font-family--life-lt;
  font-weight: 400;
  font-size: $standfirst__font-size--mobile;
  line-height: $standfirst__line-height--mobile;
  @include vertical-margin(12, bottom);
}

.caption {
  font-size: $caption__font-size--mobile;
  line-height: $caption__line-height--mobile;
  font-weight: 300;
}

.caption--medium {
  font-weight: 500;
}

a {
  color: $purple-rain--darker;
  text-decoration: none;
  transition: $transition;

  &:hover {
    color: $purple-rain--darker__select;
  }
}

// Links that require underlining (e.g. in text)
p a,
a.link--underline,
a.link--underline-on-hover:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: .12em;
}

a.link--thick-underline-on-hover:hover {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: .12em;
}

hr {
  margin: 0 auto;
  @include vertical-margin(8, bottom);
  border: 0;
  height: 2px;
  background-color: $warm-grey--dark;
}

.list-icon {
  display: inline-flex;
  align-items: flex-start;

  &:not(:last-child) {
    @include vertical-margin(3, bottom);
  }

  svg {
    margin-top: 1px;
    margin-right: 1rem;
    flex-shrink: 0;
  }
}

.list-icon__copy {
  flex-grow: 1;
}

.list-icon--dates > span {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: map-get($breakpoints, sm)) {
    display: inline-block;

    em:before {
      content: "  ·  ";
      margin: 0 .4rem;
    }
  }
}

.list-icon--event-dates > span {
  display: inline-block;
}

[class^="matrix--"] {
  ul:not(.opt-out-custom-list),
  ol:not(.opt-out-custom-list) {
    list-style: none;
    margin: 0;
    padding: 0;
    @include vertical-margin(10, bottom);

    li {
      position: relative;
      padding-left: 2.4rem;
      @include vertical-margin(3, bottom);

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
      }
    }
  }

  ul:not(.opt-out-custom-list) li:before {
    display: block;
    width: 1rem;
    height: 1rem;
    top: .9rem; // Designers.
    left: 0;
    background-color: currentColor;
    border-radius: 100%;
  }

  ol:not(.opt-out-custom-list) {
    counter-reset: dots;

    li:before {
      display: inline-block;
      content: counter(dots, decimal);
      counter-increment: dots;
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 900;
      left: 0;
      top: 0;
    }
  }

  // Reset some
  ul.iti__country-list {
    li {
      padding-left: 1rem;

      &:before {
        display: none;
      }
    }
  }
}

.link-arrow {
  display: inline-flex;
  align-items: center;
  @include vertical-margin(3, bottom);
  font-weight: 500;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.4rem;
  }
}

.anchor-marker {
  display: none; // not in play < lg
}

.text-align--center {
  text-align: center;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  body {
    font-size: $base__font-size--desktop;
    line-height: $base__line-height--desktop;
  }

  h1 {
    font-size: $h1__font-size--desktop;
    line-height: $h1__line-height--desktop;
  }

  .h1--small,
  h2 {
    font-size: $h2__font-size--desktop;
    line-height: $h2__line-height--desktop;
  }

  h3 {
    font-size: $h3__font-size--desktop;
    line-height: $h3__line-height--desktop;
  }

  h4 {
    font-size: $h4__font-size--desktop;
    line-height: $h4__line-height--desktop;
  }

  .arrow-heading {
    font-size: 2.8rem;
    line-height: 3.4rem;

    svg {
      width: 2rem;
      height: 2rem;
      margin-left: .8rem;
      transform: translateY(.2rem);
    }

    &:hover svg {
      transform: translateY(.2rem) translateX(.4rem);
    }
  }

  .standfirst {
    font-size: $standfirst__font-size--desktop;
    line-height: $standfirst__line-height--desktop;
  }

  .caption {
    font-size: $caption__font-size--desktop;
    line-height: $caption__line-height--mobile;
  }

  [class^="matrix--"] {
    ul:not(.opt-out-custom-list),
    ol:not(.opt-out-custom-list) {
      li {
        padding-left: 2.8rem;
      }
    }

    ul:not(.opt-out-custom-list) li:before {
      width: 1.2rem;
      height: 1.2rem;
      top: 1rem;
    }

    ol:not(.opt-out-custom-list) li:before {
      font-size: 2.2rem;
      line-height: 3.2rem;
      top: 0;
    }
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  // Anchor links on headings
  .matrix--heading {
    h2, h3 {
      position: relative;
    }

    .anchor-marker {
      display: none; // Reveal on hover
      position: absolute;
      font-family: $font-family--apercu;
      font-weight: 500;
      left: 0;
      transform: translateX(calc(-100% - #{$container__gutter--wide}));
    }
  }
}

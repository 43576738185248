.page-header {
  display: flex;
  flex-direction: column;
  @include vertical-padding(14, top);
  @include vertical-padding(14, bottom);
  position: relative;
}

.page-header + .body-content > .matrix--text:first-child {
  margin-top: map-get($vertical-spacers--mobile, 8) * -1;
  @media screen and (min-width: map-get($breakpoints, sm)) {
    margin-top: map-get($vertical-spacers--desktop, 10) * -1;
  }
}

// E.g. on the search results page
.page-header--compressed {
  padding-bottom: 0;
  h1 {
    @include vertical-margin(6, bottom);
  }
}

.page-header--shape-config-1 {
  .shape__circle {
    width: 100px;
    height: 100px;
    top: 8px;
    right: -50px;
  }

  .shape__rect {
    width: 30px;
    height: 200px;
    transform: rotate(-45deg);
    transform-origin: top;
    top: 13px;
    left: -60px;
  }
}

.page-header--shape-config-2 {
  .shape__circle {
    width: 180px;
    height: 180px;
    bottom: -116px;
    right: -50px;
  }

  .shape__rect {
    width: 30px;
    height: 200px;
    transform: rotate(40deg);
    transform-origin: top;
    top: 24px;
    left: 55px;
  }
}

.page-header--shape-config-3 {
  .shape__circle {
    width: 100px;
    height: 100px;
    left: 14px;
    bottom: -62.5px;
  }

  .shape__rect {
    width: 30px;
    height: 200px;
    transform: rotate(-45deg);
    transform-origin: top;
    top: 93px;
    right: 72px;
  }
}

.page-header--shape-config-4 {
  .shape__circle {
    width: 100px;
    height: 100px;
    top: 8px;
    left: -36px;
  }

  .shape__rect {
    width: 30px;
    height: 200px;
    transform: rotate(30deg);
    transform-origin: top;
    bottom: -115px;
    right: 38px;
  }
}

.page-header--shape-config-5 {
  .shape__circle {
    width: 180px;
    height: 180px;
    right: -122px;
    bottom: 116px;
  }

  .shape__rect {
    width: 30px;
    height: 200px;
    transform: rotate(-30deg);
    transform-origin: bottom;
    bottom: -100px;
    right: -25px;
  }
}

.entry-meta {
  display: flex;
  flex-direction: column;
  @include vertical-margin(3, top);
  @include vertical-margin(10, bottom);
}

.entry-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .button--cta {
    margin-bottom: 1.6rem;
  }
}

.page-header__copy {
  position: relative;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .entry-actions {
    flex-direction: row;
    align-items: center;

    .button--cta {
      margin-bottom: 0;
      margin-right: 2.4rem;
    }
  }

  .page-header--shape-config-1 {
    .shape__circle {
      width: 200px;
      height: 200px;
      top: 7px;
      right: calc( ((100% - #{$container--narrow__max-width}) / 2) + 28px);
    }

    .shape__rect {
      width: 60px;
      height: 400px;
      top: 20%;
    }
  }

  .page-header--shape-config-2 {
    .shape__circle {
      width: 400px;
      height: 400px;
      right: calc( ((100% - #{$container--narrow__max-width}) / 2) - 140px);
      bottom: -262px;
    }

    .shape__rect {
      width: 60px;
      height: 400px;
      top: 57px;
      left: 183px;
    }
  }

  .page-header--shape-config-3 {
    .shape__circle {
      width: 200px;
      height: 200px;
      bottom: -94px;
      left: -60px;
    }

    .shape__rect {
      width: 60px;
      height: 400px;
      top: 165px;
      right: 180px;
    }
  }

  .page-header--shape-config-4 {
    .shape__circle {
      width: 200px;
      height: 200px;
      top: 20px;
      left: calc( ((100% - #{$container--narrow__max-width}) / 2) - 76px );
    }

    .shape__rect {
      width: 60px;
      height: 400px;
      transform-origin: bottom;
      bottom: -100px;
      right: calc( ((100% - #{$container--narrow__max-width}) / 2) + 100px );
    }
  }

  .page-header--shape-config-5 {
    .shape__circle {
      width: 400px;
      height: 400px;
      top: 30px;
      right: -232px;
    }

    .shape__rect {
      width: 60px;
      height: 400px;
      bottom: -50%;
      right: calc( ((100% - #{$container--narrow__max-width}) / 2) - 160px);
    }
  }
}

@media (min-width: $container__max-width) {
  .page-header--shape-config-3 {
    .shape__circle {
      left: calc( ((100% - #{$container__max-width}) / 2) - 40px);
    }

    .shape__rect {
      right: calc( ((100% - #{$container__max-width}) / 2) + 80px);
    }
  }
}

@media (min-width: 1430px) {
  .page-header--shape-config-2 {
    .shape__rect {
      left: calc( ((100% - #{$container--narrow__max-width}) / 2) - 130px);
    }
  }
}

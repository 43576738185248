.news-cards {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.news-card {
  background-color: $warm-grey--light;
  padding: 2.4rem;
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s;

  &:not(:last-child) {
    @include vertical-margin(10, bottom);
  }
}

.news-card__image {
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
  aspect-ratio: 16 / 9;
  background-color: $warm-grey--dark;
  overflow: hidden;
  @include vertical-margin(10, bottom);
  border-radius: .4rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.news-card__title {
  margin-top: 0;
  @include vertical-margin(4, bottom);

  a {
    color: inherit;
  }
}

.news-card__button {
  @include vertical-margin(8, top);
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .news-card {
    flex-direction: row;
    align-items: flex-start;
    padding: 3.2rem 3.2rem 4rem;
  }

  .news-card__image,
  .news-card__text {
    width: calc(50% - 1.6rem);
  }

  .news-card__image {
    margin-bottom: 0;
    margin-right: 1.6rem;
  }

  .news-card__text {
    margin-left: 1.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .news-card {
    padding: 5.6rem 6.4rem;
  }

  .news-card__image,
  .news-card__text {
    width: calc(50% - 2.4rem);
  }

  .news-card__image {
    margin-right: 2.4rem;
  }

  .news-card__text {
    margin-left: 2.4rem;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100vh;
  scroll-behavior: smooth;
}

.overflow-clip {
  overflow: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

main {
  flex-grow: 1;
  overflow: visible;
}

iframe,
img,
video,
svg {
  max-width: 100%;
}

// Remove webkit search clear icon
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

iframe {
  border: 0;
}

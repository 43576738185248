// Used for signpost links and download blocks

.icon-link {
  @include vertical-margin(6, top);
  @include vertical-margin(14, bottom);
  width: 100%;
  max-width: 656px;
  display: flex;
  align-items: flex-start;
  font-weight: 500;

  &:hover .icon-link__icon {
    background-color: $purple-rain--darker__select;
  }
}

.matrix--icon-link + .matrix--icon-link {
  margin-top: map-get($vertical-spacers--mobile, 14) * -1;
}

.icon-link__icon {
  flex-grow: 0;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: $purple-rain--darker;
  color: $warm-grey--light;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  margin-right: 2.4rem;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  .icon-link__icon--external {
    margin-right: -1px;
  }
}

.icon-link--anchor .icon-link__icon svg {
  transform: rotate(90deg);
}

.icon-link__text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @include vertical-margin(6, top);
}

.icon-link__download-meta {
  display: block;
  color: $dark-grey;
  font-weight: 400;
  @include vertical-margin(2, top);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .icon-link__icon {
    width: 72px;
    height: 72px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .matrix--icon-link + .matrix--icon-link {
    margin-top: map-get($vertical-spacers--desktop, 14) * -1;
  }
}

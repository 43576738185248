ul.social-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    @include vertical-margin(5, bottom);
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.social-link {
  display: flex;
  align-items: center;

  &:hover {
    .social-link__icon {
      background-color: $purple-rain--darker__select;
    }
  }
}

.social-link__icon {
  margin: 0 1rem 0 0;
  flex-shrink: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $warm-grey--light;
  background-color: $purple-rain--darker;
  width: 32px;
  height: 32px;
  border-radius: 100%;

  svg {
    display: block;
    width: 16px;
    height: auto;
    max-height: 16px;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .social-link__icon {
    width: 40px;
    height: 40px;
    margin: 0 1.2rem 0 0;

    svg {
      width: 20px;
      max-height: 20px;
      height: auto;
    }
  }
}

body {
  background-color: $warm-grey--background;
}

.container {
  width: 100%;
  max-width: $container__max-width;
  padding-left: $container__gutter;
  padding-right: $container__gutter;
  margin-left: auto;
  margin-right: auto;
}

.container--wide {
  max-width: $container__max-width;
}

.container--medium {
  max-width: $container--medium__max-width;
}


.container--narrow {
  max-width: $container--narrow__max-width;
}

.body-content {
  display: flex;
  flex-direction: column;
  @include vertical-padding(18, bottom);
}

.home-cards {
  padding-bottom: calc(map-get($vertical-spacers--mobile, 16) - map-get($vertical-spacers--mobile, 12));
}

.home-latest {
  @include vertical-padding(16, top);
  @include vertical-padding(18, bottom);
}

.landing-content,
.listing-content {
  display: flex;
  flex-direction: column;
  @include vertical-padding(14, bottom);
}


.matrix--text {
  @include vertical-margin(12, bottom);

  *:last-child {
    margin-bottom: 0;
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: 440px) {
  .container {
    padding-left: $container__gutter--wide;
    padding-right: $container__gutter--wide;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .home-cards {
    padding-bottom: calc(map-get($vertical-spacers--desktop, 16) - map-get($vertical-spacers--desktop, 12));
  }
}

.related-content .card-grid {
  padding-top: 0;
  padding-bottom: 0;
}

.related-content__section-heading {
  text-align: center;
  @include vertical-margin(12, bottom);
}

.related-content__grain {
  background-repeat: repeat-x;
  background-image: url("/dist/images/grain--gradient.png");
  background-position: bottom;
}

.related-content__purple {
  background-color: $deep-purple;
}

.site-header {
  .container {
    position: relative;
  }
}

.site-header__shape {
  display: none;
}

.site-header__top {
  padding-top: map-get($vertical-spacers--mobile, 10);
  @include vertical-padding(10, bottom);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.site-logo img {
  display: block;
  mix-blend-mode: multiply;
  height: 48px;
  width: auto;
}

.site-header__top-actions--mobile {
  display: flex;

  button {
    margin-left: .8rem;
  }
}

#mobileNavButton {
  position: relative;

  .nav-icon--close {
    display: none;
  }

  &[aria-expanded] {
    .nav-icon--burger {
      display: none;
    }

    .nav-icon--close {
      display: block;
    }
  }

  .nav-icon--active {
    display: none;
    position: absolute;
    width: 28px;
    height: 21px;
    top: calc(100% + 2px); // SVG is subpixelling
    left: 0;
    right: 0;
    margin: 0 auto;
    pointer-events: none;
    color: $purple-rain--darker;
  }

  &:hover {
    background-color: $purple-rain--darker;
    color: $warm-grey--light;
  }
}

.site-header__top-actions--desktop {
  display: none;
}

.site-header__nav {
  display: none; // reveal with JS on mobile
  z-index: 1;
  background-color: $purple-rain--darker;
  color: $warm-grey--light;
  position: absolute;
  top: calc(100% - 7px);
  left: 0;
  right: 0;
  padding-left: $container__gutter;
  padding-right: $container__gutter;
  padding-top: map-get($vertical-spacers--mobile, 10);
  padding-bottom: map-get($vertical-spacers--mobile, 11);

  > .button--cta {
    margin-bottom: map-get($vertical-spacers--mobile, 10);
  }

  > .button--white:hover {
    background-color: $warm-grey--light;
    color: $purple-rain--darker;
  }
}

.main-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  a {
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }
}

.main-nav__top-level-li {
  border-top: 1px solid $warm-grey--light;
}

.main-nav__top-level-a {
  padding: map-get($vertical-spacers--mobile, 5) 0;
  color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:active {
    color: inherit;
  }

  span {
    flex-grow: 1;
  }

  &[aria-haspopup="true"] span {
    margin-right: .8rem;
  }

  .nav-chevron {
    flex-grow: 0;
    flex-shrink: 0;
    width: 1.7rem;
    height: auto;
    transition: transform .2s ease-out;
  }

  .active-nav-item-triangle {
    display: none;
  }
}

.main-nav__top-level-a--active .nav-chevron {
  transform: rotate(180deg);
}

.main-nav__second-level {
  margin: 0 0 map-get($vertical-spacers--mobile, 10);
  border-top: 1px solid $warm-grey--light;
  display: none;
  flex-direction: column;
}

.main-nav__second-level-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.main-nav__second-level-a {
  display: inline-flex;
  align-items: center;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: .12em;
  }

  svg {
    flex-grow: 0;
    flex-shrink: 0;
    width: 1.4rem;
    height: auto;
    margin-left: .8rem;
  }
}

.main-nav__second-level--lead {
  margin-bottom: map-get($vertical-spacers--mobile, 8);

  .main-nav__second-level-a {
    padding-top: map-get($vertical-spacers--mobile, 5);
    padding-bottom: map-get($vertical-spacers--mobile, 4);
  }
}

.main-nav__second-level-standfirst {
  margin-bottom: 0;
}

.main-nav__second-level-li {
  border-left: 1px solid $warm-grey--light;

  .main-nav__second-level-a {
    padding: .8rem 0 .8rem 1.6rem;
  }

  &:first-child .main-nav__second-level-a {
    padding-top: .4rem;
  }

  &:last-child .main-nav__second-level-a {
    padding-bottom: .4rem;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .site-logo img {
    height: 80px;
  }

  .site-header__top {
    padding-top: map-get($vertical-spacers--desktop, 14);
  }

  .site-header__nav {
    top: calc(100% - 19px);
  }
}

@media screen and (min-width: $breakpoint__header) {
  .site-header {
    margin-bottom: 30px;
  }

  .site-header__shape {
    display: block
  }

  .site-header--shape-config-1 .shape__circle,
  .site-header--shape-config-4 .shape__circle {
    width: 27%;
    height: auto;
    max-width: 400px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-74.5%);
  }

  .site-header--shape-config-2 .shape__circle {
    width: 13.8%;
    height: auto;
    max-width: 200px;
    left: 30%;
    transform: translateY(-46%);
  }

  .site-header--shape-config-3 .shape__circle {
    width: 27%;
    height: auto;
    max-width: 400px;
    left: 20%;
    transform: translateY(-74.5%);
  }

  .site-header--shape-config-5 {
    position: relative;

    .container {
      position: initial;
    }

    .shape__circle {
      top: 32px;
      width: 200px;
      height: 200px;
      left: -112px;
    }
  }

  .site-header__top-actions--mobile {
    display: none;
  }

  .site-header__top-actions--desktop {
    display: flex;
    align-items: center;

    .button--cta {
      padding-top: 0;
      padding-bottom: 0;
      height: 56px;
      align-items: center;
      text-align: center;
      margin-left: 1.6rem;
    }

    input[type="search"] {
      width: 248px;
    }
  }

  .site-header__nav {
    display: block;
    background-color: transparent;
    position: relative;
    padding: 0;
    font-size: 2.4rem;

    > .button--cta {
      display: none;
    }
  }

  .main-nav {
    flex-direction: row;
    background-color: $warm-grey--light;
    border-radius: .4rem;
    padding: 0 2rem;
  }

  .main-nav__top-level-a {
    color: $purple-rain--darker;
    padding: 1.6rem 2rem;
    position: relative;

    .nav-chevron {
      width: 2rem;
    }

    &:hover,
    &:active {
      color: $purple-rain--darker__select;
    }

    .active-nav-item-triangle {
      position: absolute;
      width: 40px;
      height: 30px;
      top: calc(100% + 2px); // SVG is subpixelling
      left: 0;
      right: 0;
      margin: 0 auto;
      color: $purple-rain--darker;
    }
  }

  .main-nav__second-level {
    background-color: $purple-rain--darker;
    border-top: 0;
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    right: 0;
    border-radius: .4rem;
    padding: 4.4rem 4rem;
    flex-direction: row;
  }

  .main-nav__second-level--lead {
    margin-bottom: 0;
    width: 366px;
    padding-right: 4rem;
    flex-grow: 0;
    flex-shrink: 0;

    .main-nav__second-level-a {
      padding: .4rem 0 .6rem;
    }
  }

  .main-nav__second-level-standfirst {
    padding-top: .6rem;
  }

  .main-nav__second-level-ul {
    flex-grow: 1;
  }

  .main-nav__second-level-li {
    .main-nav__second-level-a {
      padding: .6rem 0 .6rem 4rem;
    }

    &:last-child .main-nav__second-level-a {
      padding-bottom: 0;
    }
  }

  .main-nav__second-level-a svg {
    width: 1.8rem;
  }
}

.share {
  // @include vertical-padding(3, top);
  display: flex;
  position: relative;
}

#shareButton {
  padding: 0;
  border: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  color: $purple-rain--darker;
  background-color: transparent;

  svg {
    margin-top: -1px; // Designer!
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: $purple-rain--darker__select;
  }
}

#shareMenu {
  display: none;
  margin: 24px 0 0;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  @include vertical-padding(8, top);
  @include vertical-padding(5, bottom);
  list-style: none;
  background-color: $warm-grey--light;
  border-radius: .4rem;
  width: 237px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;

  li {
    @include vertical-margin(5, bottom);
  }

  // Triangle
  &:before {
    content: "";
    width: 32px;
    height: 24px;
    display: block;
    position: absolute;
    top: -23px;
    left: 1.6rem;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    background-image: url("/dist/images/share-menu-triangle.svg");
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  #shareMenu {
    padding-left: 2.4rem;
    padding-right: 2.4rem;

    &:before {
      width: 40px;
      height: 30px;
      top: -29px;
      left: 2.4rem;
    }
  }

  .share-item__icon {
    width: 40px;
    height: 40px;
    margin: 0 1.2rem 0 0;

    svg {
      width: 20px;
      max-height: 20px;
      height: auto;
    }
  }
}


.matrix--data-table table {
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-size: $h4__font-size--mobile;
  line-height: $h4__line-height--mobile;
  font-weight: 500;

  &:last-child {
    padding-right: 0;
  }
}

thead tr {
  vertical-align: bottom;
}

tbody tr {
  vertical-align: top;
}

/* Force table to not be like tables anymore */
table.rt-responsive-table.rt-vertical-table, table.rt-responsive-table.rt-vertical-table thead, table.rt-responsive-table.rt-vertical-table tbody, table.rt-responsive-table.rt-vertical-table th, table.rt-responsive-table.rt-vertical-table td, table.rt-responsive-table.rt-vertical-table tr {
	display: block;
	overflow: hidden;
}

/* Hide table headers (but not display: none;, for accessibility) */
table.rt-responsive-table.rt-vertical-table thead tr {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

table.rt-responsive-table.rt-vertical-table {
  display: flex;
  flex-direction: column;
  @include vertical-padding(4, top);
  border-top: 2px solid $dark-grey;

  tr:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  td:before {
    display: block;
    font-size: $h4__font-size--mobile;
    line-height: $h4__line-height--mobile;
    @include vertical-padding(2, bottom);
    font-weight: 500;
    border-bottom: 2px solid $warm-grey--dark;
  }

  .responsive-container {
    padding: 1.6rem 0 2.4rem;
  }
}

// Vertical tables - used in form submission response templates
table.table--vertical-headings {
  @include vertical-margin(10, bottom);

  th {
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    width: 30%;
    min-width: 200px;
  }

  td {
    border: none;

    ul {
      margin: 0;
      padding: 0 0 0 1.5rem;
      list-style: inside;
    }
  }

  th, td {
    @include vertical-padding(4, top);
    @include vertical-padding(4, bottom);
  }

  tr {
    border-bottom: 1px solid $warm-grey--dark;

    &:last-child {
      border-bottom: 2px solid $warm-grey--dark;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  table {
    border-top: 2px solid $dark-grey;
  }

  th {
    font-size: $h4__font-size--desktop;
    line-height: $h4__line-height--desktop;
  }

  td, th {
    @include vertical-padding(4, top);
    @include vertical-padding(8, bottom);
    padding-right: 1.6rem;
    &:last-child {
      padding-right: 0;
    }
  }

  td {
    border-top: 2px solid $warm-grey--dark;
  }
}

.quote {
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  @include vertical-padding(11, top);
  @include vertical-padding(11, bottom);
  margin-left: 0;
  margin-right: 0;
  border-top: 2px solid $warm-grey--dark;
  border-bottom: 2px solid $warm-grey--dark;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(156px + #{map-get($vertical-spacers--mobile, 11) * 2});
}

.quote__headshot {
  flex-shrink: 0;
  height: auto;
  margin-right: 2.4rem;
  margin-bottom: map-get($vertical-spacers--mobile, 8);

  img {
    width: 112px;
    height: 156px;
    clip-path: url(#quoteClipPath--mobile);
  }

  img,
  svg {
    display: block;
  }
}

.quote__contents {
  flex-grow: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quote__blockquote {
  margin: 0;
  padding: 0;
  font-family: $font-family--life-lt;
  color: $deep-purple;
}

.quote__reference {
  @include vertical-margin(5, top);
  font-weight: 500;
  color: $deep-purple;
}

.quote__link {
  @include vertical-margin(5, top);
}

.quote__mark {
  display: block;
  width: 112px;
  height: 156px;
}

.quote__mark--right {
  color: $bright-yellow--lighter;
  position: absolute;
  top: map-get($vertical-spacers--mobile, 11);
  right: 0;
  transform: rotate(180deg);
}

@media screen and (min-width: 440px) {
  .quote {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .quote {
    min-height: calc(222px + #{map-get($vertical-spacers--desktop, 11) * 2});
  }

  .quote__headshot {
    margin-bottom: 0;
    margin-right: 3.2rem;

    img {
      width: 160px;
      height: 222px;
      clip-path: url(#quoteClipPath--desktop);
    }
  }

  .quote__mark {
    width: 160px;
    height: 222px;
    top: map-get($vertical-spacers--desktop, 11);
  }
}

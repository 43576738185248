.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -.4rem;
  padding: 0;

  > .button--tag {
    margin: .4rem;
  }
}

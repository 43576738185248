  .author {
  @include vertical-margin(14, bottom);
  @include vertical-padding(8, top);
  @include vertical-padding(8, bottom);
  display: flex;
  border-top: 2px solid $warm-grey--dark;
  border-bottom: 2px solid $warm-grey--dark;
}

.author__headshot {
  flex-shrink: 0;
  background-color: $purple-rain--darker;
  border-radius: 100%;
  overflow: hidden;
  width: 56px;
  height: 56px;
  margin: 0 1.6rem 0 0;
  padding: 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.author__content {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .author {
    align-items: center;
  }

  .author__headshot {
    width: 96px;
    height: 96px;
    margin: 0 2.4rem 0 0;
  }
}

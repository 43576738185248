.statistics {
  text-align: center;
  font-weight: 500;
  color: $deep-purple;
  @include vertical-margin(12, bottom);
  margin-left: -.8rem;
  margin-right: -.8rem;
}

.statistics__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -.8rem;
}

.statistic {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // max-width: calc($container__max-width / 3);
  // margin-top: calc(map-get($vertical-spacers--mobile, 10) - 2.3rem);
  margin-top: map-get($vertical-spacers--mobile, 10);
  margin-left: .8rem;
  margin-right: .8rem;
}

// .statistic,
// .statistic * {
//   outline: 1px solid red;
// }

.statistic__stat {
  display: inline-block;
  font-size: 5.6rem;
  line-height: 5.6rem;
  position: relative;
  padding-top: 2.3rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  @include vertical-padding(6, bottom);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-image: url("/dist/images/highlighter-mark.png");
    background-size: 100% 100%;
    z-index: -1;
  }
}

.statistic__caption {
  color: $deep-purple;
  padding: 0 1.6rem;
  max-width: 368px;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .statistic {
    flex: 1;
    min-width: 340px;
    margin-top: calc(map-get($vertical-spacers--desktop, 10) - 2.2rem);
  }

  .statistic__stat {
    font-size: 8rem;
    line-height: 9rem;
    padding-top: 2.2rem;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

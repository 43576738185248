.quick-sign-up {
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  background-color: $warm-grey--dark;
  border-radius: .4rem;
  padding: 4.4rem 2.4rem 4.8rem;
  position: relative;
  overflow: hidden;

  h3 {
    margin-top: 0;
  }

  input {
    &::placeholder {
      color: $purple-rain--darker;
      font-weight: 400;
      text-align: center;
    }
  }

  // Hide any fields that have been added that aren't type email
  .fui-field:not(.fui-type-email) {
    display: none;
  }

  .fui-label,
  .fui-alert-error {
    @extend .visually-hidden;
  }

  .fui-i {
    max-width: 606px;
  }

  .fui-input-container {
    margin-top: 0;
  }

  .fui-field {
    min-width: auto;
    margin-bottom: 1rem;
  }

  .shape__circle {
    width: 96px;
    height: 96px;
    bottom: -58px;
    right: 31px;
    z-index: 0;
  }

  .shape__rect {
    height: 240px;
    width: auto;
    transform: rotate(-40deg);
    top: 60px;
    right: -20px;
    z-index: 0;
  }

  .fui-btn.fui-submit {
    border-radius: .4rem;
    @extend .button--purple;
    width: 100%;
    justify-content: center;
  }
}

.quick-sign-up__content {
  position: relative;
}


@media screen and (min-width: map-get($breakpoints, sm)) {
  .quick-sign-up {
    padding: 7.2rem 9.6rem 8rem;

    input::placeholder {
      text-align: left;
    }

    .fui-field {
      margin-bottom: 0;
    }

    .fui-page {
      display: flex;
    }

    .fui-page-container {
      flex-grow: 1;
    }

    .fui-btn-container {
      flex-shrink: 0;
    }

    input[type="email"],
    .fui-btn.fui-submit {
      height: 56px;
      padding-top: 0;
      padding-bottom: 0;
    }

    input[type="email"] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .fui-btn.fui-submit {
      align-items: center;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .shape__circle {
      width: 112px;
      height: 112px;
      bottom: -62px;
      right: 40px;
    }

    .shape__rect {
      height: 300px;
      transform: rotate(-140deg);
      top: -180px;
      right: 60px;
    }
  }
}

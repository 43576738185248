.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagination__count {
  display: block;
  @include vertical-margin(4, bottom);
}

.filter-search-wrapper {
  @include vertical-margin(14, bottom);
}

.filter {
  .accordion {
    margin: 0;
  }
}

.filter .accordion__body {
  padding-right: 1.6rem;
}

.filter-form {
  display: flex;
  flex-direction: column;
}

.filter-form__contents {
  flex-grow: 1;
}

.filter-form__fieldset {
  margin: 0;
  @include vertical-margin(10, bottom);
  padding: 0;
  border: 0;

  legend {
    @extend h4;
  }
}

.filter-form__actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  .button--cta:not(:last-child) {
    margin-top: .8rem;
  }
}

.filter-form__count {
  margin-top: map-get($vertical-spacers--mobile, 8);
}


@media screen and (min-width: map-get($breakpoints, sm)) {
  .filter .accordion__body {
    padding-right: 2.4rem;
  }

  .filter-form__actions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .button--cta:not(:last-child) {
      margin-top: 0;
      margin-right: .8rem;
    }
  }

  .filter-form__count {
    flex-grow: 1;
    margin-top: 0;
  }
}

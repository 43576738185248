.site-footer {
  width: 100%;
  @include vertical-padding(16, top);
  @include vertical-padding(14, bottom);
  background-color: $deep-purple;
  color: $warm-grey--light;

  a {
    color: inherit;
    @extend .link--thick-underline-on-hover;
  }
}

.site-footer__newsletter {
  @include vertical-margin(3, top);
  @include vertical-margin(18, bottom);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 623px;
  text-align: center;

  label {
    @extend .visually-hidden;
  }

  .fui-input-container {
    margin: 0;
  }

  .fui-field {
    margin-bottom: 1rem;
  }

  input {
    box-shadow: none;

    &::placeholder {
      color: $purple-rain--darker;
      font-weight: 400;
      text-align: center;
    }
  }

  .fui-btn.fui-submit {
    @extend .button--yellow;
    width: 100%;
    justify-content: center;
  }
}

.site-footer__newsletter-copy {
  h2 {
    @include vertical-margin(8, top);
    @include vertical-margin(6, bottom);
    color: $mellow-yellow;
  }

  p {
    font-family: $font-family--life-lt;
    font-weight: 400;
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
    @include vertical-margin(10, bottom);
  }
}

.site-footer__newsletter-disclaimer {
  @include vertical-margin(5, top);
}

.site-footer__section {
  border-top: 1px solid $footer-rule;
}

.site-footer__section--info {
  @include vertical-padding(7, top);
  @include vertical-padding(8, bottom);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.site-footer__section--info__copy {
  display: flex;
  flex-direction: column;
}

.site-footer__patron {
  @include vertical-margin(6, top);
}

.site-footer__fundraising-regulator-logo {
  display: block;
  height: 56px;
  width: auto;
  margin-top: map-get($vertical-spacers--mobile, 6);
}

.site-footer__section--nav {
  @include vertical-padding(6, top);
  padding-bottom: calc(map-get($vertical-spacers--mobile, 7) - 1.2rem);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  li {
    margin-bottom: 1.2rem;
  }
}

.site-footer__section--social {
  @include vertical-padding(6, top);
  @include vertical-padding(7, bottom);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  li:not(:last-child) {
    margin: 0 1.6rem 0 0;
  }

  .social-link__icon {
    margin: 0;
    background-color: $warm-grey--light;
    color: $deep-purple;
  }

  .social-link:hover .social-link__icon {
    background-color: $warm-grey--dark;
    color: $deep-purple;
  }

  .social-link__label {
    position: absolute;
    left: -9999em;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.site-footer__section--credits {
  @include vertical-padding(6, top);
  font-size: $caption__font-size--mobile;
  line-height: $caption__line-height--mobile;
  display: flex;
  flex-direction: column;
}

.site-footer__organisation-registration-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: map-get($vertical-spacers--mobile, 6);

  p {
    margin: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .site-footer__newsletter {
    .fui-form {
      max-width: 426px;
      margin: 0 auto;
    }

    .fui-page {
      display: flex;
    }

    .fui-page-container {
      flex-grow: 1;
    }

    .fui-btn-container {
      flex-shrink: 0;
    }

    .fui-field {
      margin-bottom: 0;
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::placeholder {
        text-align: left;
      }
    }

    input,
    .fui-btn.fui-submit {
      padding-top: 0;
      padding-bottom: 0;
      height: 56px;
    }

    .fui-btn.fui-submit {
      align-items: center;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .site-footer__newsletter-copy {
    p {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  .site-footer__section--info {
    flex-direction: row;
  }

  .site-footer__section--info__copy {
    flex-grow: 1;
  }

  .site-footer__fundraising-regulator-logo {
    margin-top: 0;
    margin-left: 1.6rem;
    height: 72px;
    flex-shrink: 0;
  }

  .site-footer__address br {
    display: none;
  }

  .site-footer__section--nav {
    padding-bottom: calc(map-get($vertical-spacers--desktop, 7) - 1.3rem);

    ul {
      max-width: 580px;
      display: block;
      columns: 2;
    }

    li {
      margin-bottom: 1.3rem;
    }
  }

  .site-footer__section--credits {
    font-size: $caption__font-size--desktop;
    line-height: $caption__line-height--desktop;
    flex-direction: row;
    justify-content: space-between;
  }

  .site-footer__wj-credit {
    flex-shrink: 0;
    margin-left: 1.6rem;
  }

  .site-footer__organisation-registration-details {
    margin-bottom: 0;
  }
}

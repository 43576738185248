.button--cta {
  display: inline-flex;
  align-items: baseline;
  font-size: $button-link__font-size--mobile;
  line-height: $button-link__line-height--mobile;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 500;
  padding: 1.4rem 3.2rem;
  transition: $transition;

  &:hover {
    cursor: pointer;
    transform: scale(1.035);
  }

  &:focus-visible {
    outline: none;
    background-color: $accessibility__highlight;
    border: 3px solid $dark-grey;
    box-shadow: 0px 0px 0px 3px $accessibility__highlight;
    color: $accessibility__text;
  }
}

// Loading states for Sprig requests
.pagination .button--cta.htmx-request {
  opacity: .5;
  cursor: wait;
}

.filter-form.htmx-request {
  opacity: .5;
  pointer-events: none;
}

.button--tag {
  display: inline-flex;
  align-items: baseline;
  border: 0;
  border-radius: .4rem;
  font-size: $caption__font-size--mobile;
  line-height: $caption__line-height--mobile;
  font-weight: 400;
  padding: .7rem 1.6rem .6rem;
  transition: $transition;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    background-color: $purple-rain--darker__select;
    outline: none;
    box-shadow: 0px 0px 0px 3px $accessibility__highlight;
  }

  // Clear icon
  > svg {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 1.2rem;
  }
}

// If the tag is unselected (as in a filter) don't show the remove icon
.button--tag.button--ghost > svg {
  display: none;
}

.tag-checkbox:checked + .button--tag.button--ghost {
  background-color: $purple-rain--darker;
  color: $warm-grey--light;

  > svg {
    display: block;
  }
}

.button--squared {
  border-radius: .4rem;
}

.button--purple {
  background-color: $purple-rain--darker;
  color: $warm-grey--light;

  &:hover {
    background-color: $purple-rain--darker__select;
    color: $warm-grey--light;
  }
}

.button--yellow {
  background-color: $mellow-yellow;
  color: $deep-purple;

  &:hover {
    background-color: $mellow-yellow--darker;
    border-color: $mellow-yellow--darker;
    color: $deep-purple;
  }
}

.button--white {
  background-color: $warm-grey--light;
  color: $purple-rain--darker;

  &:hover {
    background-color: $purple-rain--darker;
    color: $warm-grey--light;
  }
}

.button--ghost {
  background-color: transparent;
  color: $purple-rain--darker;
  box-shadow: inset 0 0 0 1px $purple-rain--darker;

  &:hover {
    background-color: $purple-rain--darker;
    color: $warm-grey--light;
  }
}

.button--disabled {
  background-color: $warm-grey--dark;
  color: $deep-purple;

  &:hover {
    color: $deep-purple;
    cursor: not-allowed;
  }
}

.button__icon {
  display: inline-block;
  width: 1.6rem;
  height: auto;
  // transform: translateY(-.1rem);

  &:first-child {
    margin-right: .8rem;
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    margin-left: .8rem;
  }
}

button {
  border: 0;

  &:hover {
    cursor: pointer;
  }
}

.button--circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 100%;

  > svg {
    display: block;
    width: 2rem;
    height: auto;
  }

  > svg:first-child,
  > svg:last-child {
    margin: 0;
  }
}

.fui-btn:not(.fui-submit) {
  @extend .button--cta;
  @extend .button--white;
  @extend .button--squared
}

.fui-btn.fui-submit {
  @extend .button--cta;
  @extend .button--purple;
  @extend .button--squared;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .button--cta {
    font-size: $button-link__font-size--desktop;
    line-height: $button-link__line-height--desktop;
    padding: 1.5rem 4rem 1.4rem;
  }

  .button--tag {
    font-size: $caption__font-size--desktop;
    line-height: $caption__line-height--desktop;

    > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

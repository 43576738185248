label,
.fui-legend {
  @include vertical-margin(3, bottom);
  display: inline-block;
  font-size: 2.1rem;
  line-height: 2.8rem;
  font-weight: 500;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  width: 100%;
  padding: 13px 12px 11px;
  border: 0;
  background-color: $warm-grey--light;
  box-shadow: inset 0 0 0 2px $purple-rain--darker;
  font-weight: 300;
  border-radius: .4rem;
  transition: all $transition;
  color: $purple-rain--darker;

  &:active {
    outline: none;
    box-shadow: inset 0 0 0 3px $purple-rain--darker__select;
    color: $purple-rain--darker__select;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 3px $purple-rain--darker__select, 0 0 0 3px $accessibility__highlight;
    color: $purple-rain--darker__select;
  }
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 1.6rem;
}

.custom-select {
  position: relative;

  select {
    appearance: none;
    margin: 0;
    padding-right: 52px;
  }

  select::-ms-expand {
    display: none;
  }

  &:after {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: $purple-rain--darker;
    color: $warm-grey--light;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center calc(50% + 2px);
    background-image: url("/dist/images/icons/chevron-down--white.svg");
    background-size: 12.25px auto;
    pointer-events: none;
  }
}

// Custom checkboxes & radios
.custom-input-label {
  position: relative;
  min-height: 32px;
  padding-left: 48px;
  padding-top: 4px;
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
  line-height: 2.8rem;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.custom-checkbox,
.custom-radio {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: .4rem;
  box-shadow: inset 0 0 0 2px $purple-rain--darker;
  background-color: $warm-grey--light;
  transition: all $transition;
}

.custom-checkbox {
  background-repeat: no-repeat;
  background-position: center;
}

.custom-radio {
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:focus ~ .custom-checkbox,
input[type="radio"]:focus ~ .custom-radio {
  box-shadow: inset 0 0 0 3px $purple-rain--darker__select, 0 0 0 3px $accessibility__highlight;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-image: url("/dist/images/icons/check--purple.svg");
}

input[type="radio"]:checked ~ .custom-radio {
  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: $purple-rain--darker__select;
    border-radius: 100%;
    display: block;
  }
}

[type="file"]::-webkit-file-upload-button {
  @extend .button--cta;
}

.cookie-preference-toggle {
  fieldset {
    margin: 0;
    @include vertical-margin(10, bottom);
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

// Fields put in a loading state by ajax sprig requests
input.htmx-request {
  opacity: .5;
  cursor: wait;
  pointer-events: none;
}

//--------//
// Formie //
//--------//

.fui-row {
  margin: 0 -15px;
  flex-direction: column;
}

.fui-field {
  margin: 0 15px;
  @include vertical-margin(10, bottom);
}

.fui-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fui-instructions {
  @include last-child-no-bottom-margin;
  @include vertical-margin(3, bottom);
}

.fui-input-container {
  @include vertical-margin(3, top);
  width: 100%;
}

.fui-fieldset {
  padding: 0;
  border: 0;
}

// Fields that have multiple sub-fields don't want their own bottom margin
.fui-subfield-fieldset {
  margin-bottom: -28px;
}

.fui-checkbox,
.fui-radio {
  &:not(:last-child) {
    @include vertical-margin(5, bottom);
  }
}

.fui-layout-wrap {
  flex-wrap: wrap;
  @include vertical-margin(3, top);
}

// Required inversion
.fui-field:not(.fui-field-required) > .fui-field-container > .fui-label:after{
  content: " (optional)";
}

.fui-required {
  display: none;
}

// Formie errors
.fui-alert {
  padding: 1.8rem 3.2rem;
  border-radius: .8rem;
  border: 3px solid $dark-grey;

  &.fui-alert-top-form {
    @include vertical-margin(10, bottom);
  }

  &.fui-alert-error {
    border-color: $error-red;
  }

  &.fui-alert-success {
    border-color: $success-green;
  }
}

.fui-input.fui-error {
  box-shadow: inset 0 0 0 3px $error-red;
}

.fui-field.fui-error {
  padding-left: 1.6rem;
  border-left: 3px solid $error-red;
  @include vertical-padding(3, bottom);
}

.fui-error-message {
  color: $error-red;
  font-weight: 500;
  @include vertical-margin(3, top);
}

.fui-page-title {
  @extend h3;
}

.fui-repeater-row {
  @include vertical-margin(5, bottom);
  padding: 1.5rem;
  border-radius: .4rem;
  border: 2px dashed $warm-grey--dark;
}

.fui-type-signature {
  canvas {
    background-color: $warm-grey--light;
    box-shadow: inset 0 0 0 2px $purple-rain--darker;
    width: 100%;
    height: 150px;
    border-radius: .4rem;
  }
}

.fui-type-calculations input[type="text"] {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  color: $black;
}

.fui-prev {
  margin-right: 1rem;
}

.fui-tabs {
  display: none;
}

.fui-progress-container {
  @include vertical-margin(12, top);
  @include vertical-margin(14, bottom);
}

.fui-progress,
.fui-progress-bar {
  border-radius: .8rem;
  height: 16px;
}

.fui-progress-label {
  @extend h4;
  margin-bottom: 0;
}

.fui-progress {
  font-size: 0;
  background-color: $warm-grey--dark;
  @include vertical-margin(5, top);
}

.fui-progress-bar {
  background-color: $purple-rain--darker;
}

.fui-label-below-input label,
.fui-instructions-below-input .fui-instructions  {
  margin-bottom: 0;
  @include vertical-margin(3, top);
}

.fui-layout-horizontal {
  .fui-checkbox,
  .fui-radio {
    padding-right: 50px;
  }
}

// International phone number dropdown
.fui-i .fui-form .iti--separate-dial-code {
  .iti__flag-container {
    padding: 2px;

  }

  .iti__flag-box,
  .iti__flag {
    display: none;
  }

  .iti__selected-flag {
    display: flex;
    background-color: $warm-grey--background;
    padding: 0 12.5px 0 10.5px;

    &:not([title]):after {
      content: "Country";
      display: block;
      margin-left: 1rem;
      margin-top: 1px;
      color: $purple-rain--darker;
    }
  }

  .iti__arrow {
    border: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: $purple-rain--darker;
    background-image: url("/dist/images/icons/chevron-down--white.svg");
    background-repeat: no-repeat;
    background-position: center calc(50% + 2px);
    transition: all $transition;

    &.iti__arrow--up {
      transform: rotate(180deg);
      background-position: center;
    }
  }

  .iti__selected-dial-code {
    order: 3;
    margin-left: 1rem;
    margin-top: 1px;
    font-size: inherit;
    color: $purple-rain--darker;
  }

  .iti__country-list {
    border: 0;
    color: $purple-rain--darker;
    border-radius: .4rem;

    li {
      padding: 1.2rem 1.2rem 1.2rem 1.6rem;
      margin: 0;
    }

    .iti__highlight {
      background-color: $warm-grey--background;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  label,
  .fui-label,
  .fui-legend {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    padding: 13px 16px 11px;
  }

  .custom-select:after {
    width: 32px;
    height: 32px;
    background-size: 14px auto;
  }

  .custom-input-label {
    min-height: 40px;
    padding-left: 56px;
    padding-top: 6px;
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  .custom-checkbox,
  .custom-radio {
    width: 40px;
    height: 40px;
  }

  input[type="checkbox"]:checked ~ .custom-checkbox {
    background-size: 22px auto;
  }

  input[type="radio"]:checked ~ .custom-radio:before {
    width: 22px;
    height: 22px;
  }

  //--------//
  // Formie //
  //--------//

  .fui-row {
    flex-direction: row;
  }

  .fui-field {
    min-width: 250px; // Force max of 2 fields to a row
  }

  .fui-subfield-fieldset {
    margin-bottom: -40px;
  }

  // Formie errors

  .fui-alert {
    padding: 2.4rem 3.2rem;
  }

  .fui-field.fui-error {
    padding-left: 2.4rem;
  }

  // "Narrow" fields (e.g. name prefix)
  .fui-field.fui-name-prefix {
    max-width: 140px;
    min-width: auto;
  }
}

.card-grid {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  display: grid;
  column-gap: 3.2rem;
}

.card-grid__heading {
  text-align: center;
  margin-top: 0;
  @include vertical-margin(12, bottom);
}

.card {
  display: flex;
  flex-direction: column;
  @include vertical-margin(12, bottom);
  border-radius: 0.5rem;
  overflow: hidden;
  transition: $transition;

  &:not(.card--no-hover):hover {
    cursor: pointer;
    transform: translateY(-5px);

    a {
      color: $purple-rain--darker__select;
    }

    .card__text {
      background-color: $bright-yellow--lighter;
    }

    .arrow-heading svg {
      transform: translateY(.1rem) translateX(.4rem);
    }
  }
}

.card__image {
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
  aspect-ratio: 16 / 9;
  background-color: $warm-grey--dark;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.card__text {
  flex-grow: 1;
  padding: 2.8rem 2.4rem 3.6rem;
  background-color: $warm-grey--light;
  transition: background-color 0.3s;
  position: relative;
}

.card__action-button {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);

  .button--cta {
    border-radius: 0;
    padding: .8rem 1.6rem .6rem 2.4rem;
    font-size: inherit;
    line-height: inherit;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: .8rem;
    transform: translateY(.1rem);
  }
}

.card__heading {
  margin: 0;
  @include vertical-margin(4, bottom);
  font-size: 2.4rem;
  line-height: 3rem;
}

// Cards with shapes
.card--has-shape {
  .card__text {
    position: relative;
    overflow: hidden;

    > *:not(svg) {
      position: relative;
    }
  }

  .shape__circle {
    width: 134px;
    height: 134px;
    top: -67px;
    right: 22%;
  }

  .shape__rect {
    width: 50px;
    height: auto;
    transform: rotate(45deg);
    bottom: -200px;
    right: 50%;
  }
}

.card__meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include vertical-margin(4, bottom);
}

.card .icon-list {
  @include vertical-margin(6, top);
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .card__heading {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }

  .card-grid--2-col,
  .card-grid--3-col {
    grid-template-columns: 1fr 1fr;
  }

  .card__text {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    padding-top: 4rem;
    padding-bottom: 5.6rem;
  }

  .card:not(.card--no-hover):hover .arrow-heading svg {
    transform: translateY(.2rem) translateX(.4rem);
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: 1024px) {
  .card-grid--2-col {
    column-gap: 4rem;

    .card__text {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  .card-grid--3-col {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .card__action-button .button--cta {
    padding-left: 3.2rem;
  }
}

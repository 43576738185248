//---------//
// Colours //
//---------//

// Colours
$black: #0d0802;
$dark-grey: #2b2b2b;
$deep-purple: #4f3360;
$purple-rain: #8d53e6;
$purple-rain--darker: #6d30cb;
$purple-rain--darker__select: #522498;
$mellow-yellow: #efb80b;
$mellow-yellow--darker: #eba900;
$bright-yellow--lighter: #fff864;
$bright-yellow: #fef200;
$warm-grey--background: #efefeb;
$warm-grey--light: #fdfdf9;
$warm-grey--dark: #dbdbd7;
$focus: #9ff7dc;
$footer-rule: #8f7e9a;
$navy: #002994;

$error-red: #d80000;
$success-green: #28a745;

// Accessibility focus state colours
$accessibility__highlight: $focus;
$accessibility__text: $dark-grey;
$accessibility__box-shadow: 0 0 0 3px $accessibility--text;

//--------//
// Layout //
//--------//

// Breakpoints
$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 961px,
  xl: 1200px,
  xxl: 1400px,
);
$breakpoint__header: 860px;

// Container
$container__max-width: 1184px; // 1136px + 2.4rem + 2.4rem
$container__gutter: 1.6rem;
$container__gutter--wide: 4rem;
$container--medium__max-width: 992px;
$container--narrow__max-width: 797px;

// Vertical spacers
$vertical-spacers--mobile: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 10px,
  5: 14px,
  6: 16px,
  7: 18px,
  8: 20px,
  9: 24px,
  10: 28px,
  11: 32px,
  12: 36px,
  13: 40px,
  14: 44px,
  15: 48px,
  16: 52px,
  17: 56px,
  18: 60px,
);

$vertical-spacers--desktop: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 12px,
  5: 16px,
  6: 20px,
  7: 24px,
  8: 28px,
  9: 32px,
  10: 40px,
  11: 48px,
  12: 56px,
  13: 64px,
  14: 72px,
  15: 80px,
  16: 96px,
  17: 112px,
  18: 128px,
);

//------------//
// Typography //
//------------//

// Families
$font-family--apercu: "Apercu", sans-serif;
$font-family--fugue: "Fugue", sans-serif;
$font-family--life-lt: "Life LT", serif;

$base__font-size--mobile: 2rem;
$base__line-height--mobile: 2.8rem;
$base__font-size--desktop: 2.2rem;
$base__line-height--desktop: 3.2rem;

$h1__font-size--mobile: 3.6rem;
$h1__line-height--mobile: 4rem;
$h1__font-size--desktop: 7.2rem;
$h1__line-height--desktop: 8.2rem;

$h2__font-size--mobile: 2.8rem;
$h2__line-height--mobile: 3.4rem;
$h2__font-size--desktop: 5.6rem;
$h2__line-height--desktop: 6.6rem;

$h3__font-size--mobile: 2.4rem;
$h3__line-height--mobile: 3rem;
$h3__font-size--desktop: 3.6rem;
$h3__line-height--desktop: 4.6rem;

$h4__font-size--mobile: 2rem;
$h4__line-height--mobile: 2.8rem;
$h4__font-size--desktop: 2.4rem;
$h4__line-height--desktop: 3.2rem;

$standfirst__font-size--mobile: 2.3rem;
$standfirst__line-height--mobile: 3rem;
$standfirst__font-size--desktop: 3.4rem;
$standfirst__line-height--desktop: 4.3rem;

$caption__font-size--mobile: 1.7rem;
$caption__line-height--mobile: 2.4rem;
$caption__font-size--desktop: 1.9rem;
$caption__line-height--desktop: 2.7rem;

$button-link__font-size--mobile: 2rem;
$button-link__line-height--mobile: 2rem;
$button-link__font-size--desktop: 2.4rem;
$button-link__line-height--desktop: 2.6rem;

//-------------------------//
// Animation & interaction //
//-------------------------//

$transition__duration: 0.2s;
$transition__curve: ease;
$transition: $transition__duration $transition__curve;

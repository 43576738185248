.accordion-with-logo {
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  background-color: $warm-grey--dark;
  border-radius: .4rem;
}

.matrix--accordion-with-logo + .matrix--accordion-with-logo {
  margin-top: map-get($vertical-spacers--mobile, 12) * -1;
}

.accordion-with-logo__header {
  @include vertical-padding(8, top);
  @include vertical-padding(8, bottom);
  padding: 2.8rem 1.6rem 2.8rem 2.4rem;
  background-color: transparent;
  text-align: left;
  width: 100%;
  color: inherit;

  &:hover {
    cursor: pointer;

    .accordion-with-logo__icon {
      background-color: $purple-rain--darker__select;
    }
  }

  &:focus-visible {
    border: 3px solid $purple-rain--darker__select;
    box-shadow: 0px 0px 0px 3px $accessibility__highlight;
  }

}

.accordion-with-logo__logo {
  margin: 0;
  margin-right: 2.4rem;
  @include vertical-margin(7, bottom);
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;

  img {
    display: block;
    object-fit: cover;
    height: auto;
  }
}

.accordion-with-logo__heading {
  margin: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.accordion-with-logo__header-content {
  display: flex;
  flex-grow: 1;

  h3 {
    flex-grow: 1;
  }

  svg {
    flex-grow: 0;
  }
}

// See .accordion__icon
.accordion__icon {
  margin-left: 1.6rem;
}

.accordion-with-logo--expanded .accordion__icon {
  transform: rotate(-180deg);
}

.accordion-with-logo__body {
  display: none;
  padding: 0 2.4rem 2.8rem;
}

@media screen and (min-width: 440px) {
  .accordion-with-logo__header {
    display: flex;
    align-items: center;
  }

  .accordion-with-logo__logo {
    margin-bottom: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--accordion-with-logo + .matrix--accordion-with-logo {
    margin-top: map-get($vertical-spacers--desktop, 12) * -1;
  }

  .accordion-with-logo__header {
    padding: 2.8rem 2.4rem 2.8rem 3.2rem;
  }

  .accordion-with-logo__logo {
    margin-bottom: 0;
    margin-right: 3.2rem;
    width: 128px;
    height: 128px;
  }

  .accordion-with-logo__heading {
    font-size: 2.8rem;
    line-height: 3.4rem;
    margin-top: 2px;
  }

  .accordion-with-logo__body {
    padding: 0 3.2rem 4rem;
  }

}

.search-form {
  position: relative;

  input[type="search"] {
    padding: 0 5.2rem 0 3.2rem;
    height: 56px;
    border-radius: 56px;
    box-shadow: none;
  }

  .button--circle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    transition: all $transition;
  }
}

.quick-search {
  @include vertical-margin(10, top);

  input {
    &::placeholder {
      color: $purple-rain--darker;
      font-weight: 400;
    }
  }
}

.quick-search__wrapper {
  max-width: 465px;
  position: relative;
}

.page-header .search-form {
  max-width: 464px;
}

.search-results {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  @include vertical-margin(10, top);
  @include vertical-margin(12, bottom);
}

.search-results__count {
  @include vertical-margin(8, top);
}

.search-result {
  @include vertical-padding(4, top);
  border-top: 2px solid $warm-grey--dark;

  &:not(:last-child) {
    @include vertical-padding(8, bottom);
  }
}

.search-result__heading {
  margin: 0;
}

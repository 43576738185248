.hero-image {
  position: relative;
  display: block;
  z-index: -1; // Under any page header shapes

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.hero-image--full {
  @include vertical-margin(12, bottom);
}

.hero-image--full--wave.hero-image--full--wave--top {
  img,
  .hero-image__overlay {
    clip-path: url(#heroWaveClip__clipPath);
  }

  svg {
    position: absolute;
  }
}

.hero-image--wide {
  @include vertical-margin(14, bottom);
  border-radius: .4rem;
  overflow: hidden;
}

.hero-image--homepage {
  @include vertical-margin(16, bottom);
}

.hero-image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000,.45);
  text-align: center;
  color: $warm-grey--light;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0;
    font-size: $h1__font-size--mobile;
    line-height: $h1__line-height--mobile;
  }

  .standfirst {
    margin-bottom: 0;
    @include vertical-margin(6, top);
    font-size: $standfirst__font-size--mobile;
    line-height: $standfirst__line-height--mobile;
  }
}

.hero-image--full--wave--bottom .hero-image__overlay {
  padding-bottom: 5vw;
}

.hero-image--full--wave--top .hero-image__overlay {
  padding-top: 5vw;
}

.hero-image__wave-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  margin: 0 auto;
  color: $warm-grey--background;
  width: 70%;
  max-width: 560px;
}

@media (min-width: 1024px) {
  .hero-image__overlay {
    h1 {
      font-size: $h1__font-size--desktop;
      line-height: $h1__line-height--desktop;
    }

    .standfirst {
      font-size: $standfirst__font-size--desktop;
      line-height: $standfirst__line-height--desktop;
    }
  }
}

.icon-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -.4rem;
}

.icon-list__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  background-color: $purple-rain--darker;
  color: $warm-grey--light;
  margin: .4rem;

  svg {
    width: 100%;
    height: 100%;
    max-width: 1.6rem;
    max-height: 1.6rem;
  }

  &:hover {
    background-color: $purple-rain--darker__select;
    color: $warm-grey--light;
  }
}


.video {
  display: flex;
  flex-direction: column;
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  border-radius: .4rem;
  overflow: hidden;
}

.video__title {
  background-color: $dark-grey;
  color: $warm-grey--background;
  padding: 2rem 2.4rem;

  h4 {
    margin: 0;
    width: 100%;
    max-width: 752px;
  }
}

.video__player {
  position: relative;
  padding-top: 56.25%;
}

.video__player--playing {
  .video__image,
  .video__button {
    display: none;
  }
}

.video__asset {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  border: none;
  cursor: pointer;
}

.video__button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  overflow: hidden;

  img {
    display: block;
    object-fit: cover;
    transition: transform 0.3s;
    transform: scale(1);
  }

  &:hover {
    img {
      transform: scale(1.1);
      transition: transform 5s;
    }

    #play__circle {
      fill: $purple-rain--darker__select;
    }

    #play__triangle {
      fill: $warm-grey--light;
    }
  }

  &:focus-visible {
    #play__circle {
      fill: $focus;
    }

    #play__triangle {
      fill: $dark-grey;
    }
  }
}

.video__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
}

#play__circle {
  fill: $warm-grey--light;
  transition: $transition;
}

#play__triangle {
  fill: $purple-rain--darker;
  transition: $transition;
}

.video__transcript {
  .container--narrow {
    max-width: 100%;
    padding: 0;
  }

  .accordion {
    margin-top: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .video__title {
    padding: 2.8rem 4rem;
  }

  .video__icon {
    width: 72px;
    height: 72px;
  }
}

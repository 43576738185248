.cookie-banner {
  display: flex;
  flex-direction: column;
  background-color: $deep-purple;
  color: $warm-grey--light;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  @include vertical-padding(10, top);
  @include vertical-padding(12, bottom);
  padding-right: $container__gutter;
  padding-left: $container__gutter;
  z-index: 98;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.24));
  max-height: 75vh;
  overflow: auto;

  h2 {
    font-size: $h4__font-size--mobile;
    line-height: $h4__line-height--mobile;
    margin-top: 0;
    @include vertical-margin(6, bottom);
    font-weight: 500;
  }

  p {
    font-size: $caption__font-size--mobile;
    line-height: $caption__line-height--mobile;
    @include vertical-margin(4, bottom);

    a {
      color: inherit;
      @extend .link--thick-underline-on-hover;
    }
  }
}

.cookie-banner__actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include vertical-padding(6, top);
  margin: -.4rem;

  .button {
    margin: .4rem;
    font-size: $caption__font-size--mobile;
    line-height: $caption__line-height--mobile;
  }

  // For some reason these buttons are styled differently
  .button--white {
    color: $deep-purple;

    &:hover {
      background-color: $warm-grey--dark;
      color: $deep-purple;
    }
  }
}

@media screen and (min-width: 1024px) {
  .cookie-banner {
    padding-left: 4rem;
    padding-right: 4rem;
    max-width: 832px;
    left: 3.4rem;
    bottom: 2.9rem;
    border-radius: .4rem;

    h2 {
      font-size: $h4__font-size--desktop;
      line-height: $h4__line-height--desktop;
    }

    p {
      font-size: $caption__font-size--desktop;
      line-height: $caption__line-height--desktop;
    }
  }

  .cookie-banner__actions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: -.6rem;

    .button {
      font-size: $caption__font-size--desktop;
      margin: .6rem;
    }
  }
}

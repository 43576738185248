.person {
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  background-color: $warm-grey--light;
}

.matrix--person + .matrix--person {
  margin-top: map-get($vertical-spacers--mobile, 12) * -1;
}

.person__trigger {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  font-weight: inherit;
  padding: 2.8rem 1.6rem 2.8rem 2.4rem;
  background-color: transparent;
  color: inherit;

  &:hover {
    cursor: pointer;

    .person__title {
      color: $purple-rain--darker__select;
    }

    .person__icon {
      background-color: $purple-rain--darker__select;
    }
  }

  &:focus-visible {
    border: 3px solid $purple-rain--darker__select;
    box-shadow: 0px 0px 0px 3px $accessibility__highlight;
  }
}

.person__headshot {
  margin: 0 2.4rem 0 0;
  padding: 0;
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: $warm-grey--dark;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;

  img {
    display: block;
    object-fit: cover;
  }
}

.person__header-content {
  @include vertical-margin(7, top);
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
}

.person__meta {
  flex-grow: 1;
}

.person__title {
  margin: 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: $purple-rain--darker;
}

.person__role {
  font-weight: 500;
  display: block;
}

// See .accordion__icon
.person__icon {
  margin-left: 1.6rem;
}

.person--expanded .person__icon {
  transform: rotate(-180deg);
}

.person__body {
  display: none;
  padding: 0 2.4rem 2.8rem;
}

@media screen and (min-width: 440px) {
  .person__trigger {
    flex-direction: row;
    align-items: center;
  }

  .person__header-content {
    margin-top: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--person + .matrix--person {
    margin-top: map-get($vertical-spacers--desktop, 12) * -1;
  }

  .person__trigger {
    padding: 2.8rem 2.4rem 2.8rem 3.2rem;
  }

  .person__body {
    padding: 0 3.2rem 4rem;
  }

  .person__headshot {
    width: 128px;
    height: 128px;
  }

  .person__title {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }

  .person__role {
    margin-top: .4rem;
  }
}

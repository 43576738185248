.image {
  margin: 0;
  @include vertical-margin(6, top);
  @include vertical-margin(12, bottom);

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: .4rem;
  }

  .caption {
    background-color: $warm-grey--dark;
    padding: 2rem 2.4rem;
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;

    > span {
      display: block;
      width: 100%;
      max-width: 752px;
    }
  }
}

.image--has-caption img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.image--aspect-ratio--9-16,
.image--aspect-ratio--2-3 {
  .image__wrapper {
    max-width: 531px;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .image {
    .caption {
      padding: 2.8rem 4rem;
    }
  }
}

.breadcrumb {
  font-weight: 400;

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    svg {
      width: 11px;
      height: 11px;
      margin-left: 1rem;
      margin-top: -1px;
      color: $deep-purple;
      flex-shrink: 0;
    }
  }

  li:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .breadcrumb {
    li:not(:last-child) {
      margin-right: 1.2rem;

      &:after {
        margin-left: 1.2rem;
      }
    }
  }
}
